import React, { PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import HomeIcon from '@mui/icons-material/Home';
// import ExtensionIcon from '@mui/icons-material/Extension'; reintroduce in issue #77
// import MapIcon from '@mui/icons-material/MyLocation'; reintroduce in issue #78
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import CategoryIcon from '@mui/icons-material/Category';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import LogoIcon from './LogoIcon';
import LogoFull from './LogoFull';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SearchModalProvider, SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import { SidebarDivider } from './CustomSideBarDivider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { IconComponent } from '@backstage/core-plugin-api'; // https://github.com/backstage/backstage/issues/18018
import { MyGroupsSidebarItem } from '@backstage/plugin-org';

// Importing icon from MUIv4 due to @backstage/plugin-search not supporting MUIv5 icons
import GroupIcon from '@material-ui/icons/People';


//Analytics
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { applicationInsightsReactPlugin, useAnalytics } from './Analytics';

//Hotkeys
import KeyboardShortcutSearchToggler from '../search/KeyboardShortcutSearchToggler';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
    '& .MuiLink-root': {
      width: sidebarConfig.drawerWidthClosed,
      marginLeft: '24px',
    },
  },
});

const useLogoStyles = makeStyles({
  svg: {
    height: 28,
    width: 'auto'
  }
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const { isOpen } = useSidebarOpenState();
    const { svg } = useLogoStyles();

    return (
      <div className={classes.root}>
        <Link to="/" underline="none" aria-label="Home">
          {isOpen ? <LogoFull classes={{ svg }}/> : <LogoIcon classes={{ svg }}/>}
        </Link>
      </div>
    );
  };



export const Root = ({ children }: PropsWithChildren<{}>) => {
  useAnalytics();

  // NOTE: To avoid sidebar items clogging up the recent/top visited entities on the hompage, add them to the exceptions here: src/backstage/visitfilters.yaml
  return(
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SearchModalProvider>
          <KeyboardShortcutSearchToggler />
          <SidebarSearchModal />
        </SearchModalProvider>
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon as IconComponent} to="/" text="Home" />
        <MyGroupsSidebarItem
          singularTitle="My Group"
          pluralTitle="My Groups"
          icon={GroupIcon}
        />
        <SidebarItem icon={CategoryIcon as IconComponent} to="catalog" text="Catalog" />
        {/* Hide API Docs, reintroduce in issue #77 <SidebarItem icon={ExtensionIcon as IconComponent} to="api-docs" text="APIs" /> */}
        <SidebarItem icon={LibraryBooks as IconComponent} to="docs" text="Docs" />
        <SidebarItem icon={FolderSpecialIcon as IconComponent} to="guidelines" text="Guidelines" />
        <SidebarItem icon={CallSplitIcon as IconComponent} to="walkthroughs" text="Walkthroughs" />
        <SidebarItem icon={CreateComponentIcon as IconComponent} to="create" text="Create..." />
        <SidebarDivider />
        <SidebarItem icon={LightbulbIcon as IconComponent} to="tips-and-tricks" text="Tips & Tricks" />
        {/* End global nav */}
        {/* Hide Tech Radar, reintroduce in issue #78
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
        </SidebarScrollWrapper>
        */}
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
  )
};

export default withAITracking(applicationInsightsReactPlugin, Root);
