import {
  UnifiedThemeOptions,
  createBaseThemeOptions,
  defaultTypography,
} from '@backstage/theme';
import SandvikSans from '../assets/fonts/SandvikSansText-Regular.woff2';
import { merge } from 'ts-deepmerge';

const sandvikSans = {
  fontFamily: 'Sandvik-Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('SandvikSans'),
    url(${SandvikSans}) format('woff2'),
  `,
};

const commonBaseOptions: Partial<UnifiedThemeOptions> = {
  fontFamily: 'Sandvik-Sans',
  defaultPageTheme: 'home',
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [sandvikSans],
      },
    },
    // Due to some behaviour Backstage & Mui5, original MUI v1 grids have no spacing between grid items. We set this here to mimic the old MuiV4 grid behavior.
    // See discussion here for more info: https://github.com/backstage/backstage/issues/22199#issuecomment-1918561382
    MuiGrid: {
      styleOverrides: {
        root: {
          padding: 8,
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          height: 'auto',
          '.homepage &': {
            height: '100%',
          },
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& li': {
            listStyle: 'none',
            'padding-bottom': '6px',
            'padding-left': '16px',
            'padding-right': '16px'
          }
        }
      }
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontWeight: 'normal',
        },
        label: {
          fontWeight: 'unset',
          transition: 'transform 0.3s ease-out',
          transformOrigin: 'left center',
          '&:hover': {
            transform: 'scale(1.2)',
            transition: 'transform 0s',
          },
        },
        selected: {
          "text-shadow": '.3px 0px .15px, -0.3px 0px .15px',
        },
      },
    },
  },
}

export const mergeWithCommon = (options: Partial<UnifiedThemeOptions>): UnifiedThemeOptions => merge(
  commonBaseOptions,
  createBaseThemeOptions({
    palette: {
      error: {
        main: '#EB0D0D',
      },
      warning: {
        main: '#EB0D0D',
      },
      info: {
        main: '#1441F5',
      },
      success: {
        main: '#485e30',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
    },
    typography: {
      ...defaultTypography,
      fontFamily: 'Sandvik-Sans',
      htmlFontSize: 16,
      h2: {
        fontSize: '1.25rem',
        fontWeight: 300,
        marginBottom: 10,
      },
      h3: {
        fontSize: '1.2rem',
        fontWeight: 300,
        marginBottom: 10,
      },
      h4: {
        fontSize: '1.15rem',
        fontWeight: 300,
        marginBottom: 10,
      },
      h5: {
        fontSize: '1.1rem',
        fontWeight: 300,
        marginBottom: 10,
      },
      h6: {
        fontSize: '1.05rem',
        fontWeight: 300,
        marginBottom: 10,
      },
    },
  }),
  options
) as UnifiedThemeOptions;

