import { LinkCardProps } from './LinkCard';

const content: LinkCardProps[] = [
    {
        title: 'Teams Webhooks for Databricks',
        description: 'Setup your Databricks workspace to send alerts to a Teams channel of your choice.',
        link: '/docs/default/Component/databricks-techdocs/configuration/webhooks/',
    },
    {
        title: 'Teams Webhooks for Azure',
        description: 'Configure your Azure Alerts to send messages to a Teams channel of your choice.',
        link: '/docs/default/Component/databricks-techdocs/configuration/webhooks/',
    },
    {
        title: 'Deploying Azure Endpoints on Sandnet',
        description: 'How to configure DNS, firewalls, and Zscaler when you connect a PaaS resource to Sandnet',
        link: '/docs/default/component/azure-private-endpoints',
    },
    {   
        title: 'GitHub Copilot',
        description: 'How to get access to GitHub Copilot',
        link: '/docs/default/component/github-walkthrough/copilot/'
    }
]

export default content;