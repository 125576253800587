import React from 'react';
import {
  PageWithHeader,
  Content,
  ItemCardGrid,
  ContentHeader,
  SupportButton,
} from '@backstage/core-components';

import LinkCard from './LinkCard';
import content from './Content';

const Body = () => {
    const sorted_cards = content.sort((a, b) => a.title.localeCompare(b.title));
    return (
        <ItemCardGrid>
            {sorted_cards.map((cardData, index) => (
                <LinkCard
                    key={index}
                    {...cardData}
                />
            ))}
        </ItemCardGrid>
    );
};

export const TipsAndTricksPage = () => {
  return (
    <PageWithHeader themeId="other" title="Tips And Tricks">
      <Content>
        <ContentHeader title="">
          <SupportButton>Get started at Sandvik.</SupportButton>
        </ContentHeader>
        <Body />
      </Content>
    </PageWithHeader>
  );
};
