import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import React from 'react';
import { LinkButton, ItemCardHeader } from '@backstage/core-components';

/**
 * Props for {@link LinkCard}
 *
 * @public
 */
export interface LinkCardProps {
    title: string;
    subtitle?: string;
    description: string;
    link: string;
    linkText?: string;
    tags?: string[];
}

const LinkCard = (props: LinkCardProps) => {
    const { title, subtitle = '', description, link, linkText = 'Learn More', tags = [] } = props;
    return (
        <Card>
            <CardMedia>
                <ItemCardHeader title={title} subtitle={subtitle} />
            </CardMedia>
            <CardContent>
                {tags.length ? (
                    <Box>
                        {tags.map(tag => (
                            <Chip size="small" label={tag} key={tag} />
                        ))}
                    </Box>
                ) : null}
                {description}
            </CardContent>
            <CardActions>
                <LinkButton to={link} color="primary">
                    {linkText}
                </LinkButton>
            </CardActions>
        </Card>
    );
};

export default LinkCard;
